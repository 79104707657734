
.app-header {
  background-color: #090a14;
  padding: 13px 43px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #F1F8F6;

  position: relative;
  z-index: 1;
  box-shadow: 0px 10px 14px 0px #45978626;

  .logo-link {
    margin: 0px auto;
    img {
      width: 240px;
    }
  }

  .btn-join {
    border-width: 0px 0px 2px 0px;
    background-color: #459786;
    color: #fff;
    border: unset;
    border-radius: 8px;
    border-bottom: 2px solid #3c8475;
    padding: 14px 24px 14px 24px;
    font-size: 16px;
    text-wrap: nowrap;
    white-space: nowrap;
    margin-left: 10px;
    text-decoration: none;
  }
}
